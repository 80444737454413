import $ from 'jquery';
function submitLogin(){
    $('.login-page__submit').attr('disabled', 'disabled');
}
$(function (){
    $('.form-login').on('submit', submitLogin);
    $('.form-login input').on('change keyup', function (){
        $('.login-page__submit').removeAttr('disabled');
    })
})
